import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const MyAccount = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const element = useRef<HTMLElement>(null);

  useEffect(() => {
    const currentRef = element.current;

    // this callback is active only if the params with_pet_selection_callback is set to true
    const selectPetCallback = (customEvent: any /* use your own types instead of any */) => {
      console.log('select pet details :', customEvent.detail);
      alert('Check your console to see pet details !');
    };
    currentRef?.addEventListener('selectPet', selectPetCallback);

    return () => {
       currentRef?.removeEventListener('selectPet', selectPetCallback);
    };
  }, [navigate]);

  return (
    <my-account
      ref={element}
      // Check .env file to see the content of REACT_APP_MA_WEBCOMP_LOCATION_URI and REACT_APP_MA_WEBCOMP_SIGNOUT_URI variables
      location_uri={process.env.REACT_APP_MA_WEBCOMP_LOCATION_URI}
      signout_redirect_uri={process.env.REACT_APP_MA_WEBCOMP_LOCATION_URI}
      current_location={JSON.stringify(location)}
      with_aside_nav="false"
      persistent_deeplink="pets"
    ></my-account>
  );
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'my-account': any;
    }
  }
}
